var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"PlqvmRKr-3ek0j8pV_RpS"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {SENTRY_SAMPLING_RATE} from 'sentry.constants';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV === 'production' && SENTRY_DSN === undefined) {
  console.error('SENTRY_DSN is undefined.');
}

if (SENTRY_DSN !== undefined) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_SAMPLING_RATE,
    environment: process.env.DEPLOYMENT_ENV || 'development',
    ignoreErrors: ['Abort route change'],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations:
      process.env.DEPLOYMENT_ENV === 'production'
        ? [
            new Sentry.Replay({
              // only replay on errors
              sessionSampleRate: 0,
              errorSampleRate: 1,
              maskAllText: false,
              maskAllInputs: false,
              unblock: ['svg', 'img', 'picture'],
            }),
          ]
        : [],
  });
}
